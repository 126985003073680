import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastService } from './toast.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RequestOptions } from 'http';
import { NbAuthService } from '@nebular/auth';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class HttpService {

    constructor(private httpClient: HttpClient, private toastService: ToastService, private authService: NbAuthService) {

    }

    public get(url: string, options?: RequestOptions, successMessage?: string, errorMessage?: string): Observable<any> {
        return this.httpClient
            .get(url, options)
            .pipe(
                tap(
                    res => {
                        console.info(`${url} response received!`);
                        if (successMessage) {
                            this.toastService.successToast(successMessage);
                        }
                    },
                    err => {
                        console.info(`${url} response error!`);
                        console.error(err);
                        if (err.status == 418 || err.status == 403) {
                            this.authService.logout("email").subscribe(
                                res => window.location.replace(`${environment.ADMIN_PATH}/admin/logout`)
                            )
                        }

                        if (errorMessage) {
                            this.toastService.errorToast(errorMessage);
                        }
                    }
                )
            );
    }

    public post(url: string, body?: any, options?: RequestOptions, successMessage?: string, errorMessage?: string): Observable<any> {
        return this.httpClient
            .post(url, body, options)
            .pipe(
                tap(
                    res => {
                        console.info(`${url} response received!`);
                        if (successMessage) {
                            this.toastService.successToast(successMessage);
                        }
                    },
                    err => {
                        console.info(`${url} response error!`);
                        console.error(err);
                        if (err.status == 418 || err.status == 403) {
                            this.authService.logout("email").subscribe(
                                res => window.location.replace(`${environment.ADMIN_PATH}/admin/logout`)
                            )
                        }

                        if (errorMessage) {
                            this.toastService.errorToast(errorMessage);
                        }
                    }
                )
            );
    }

    public put(url: string, body?: any, options?: RequestOptions, successMessage?: string, errorMessage?: string): Observable<any> {
        return this.httpClient
            .put(url, body, options)
            .pipe(
                tap(
                    res => {
                        console.info(`${url} response received!`);
                        if (successMessage) {
                            this.toastService.successToast(successMessage);
                        }
                    },
                    err => {
                        console.info(`${url} response error!`);
                        console.error(err);
                        if (err.status == 418 || err.status == 403) {
                            this.authService.logout("email").subscribe(
                                res => window.location.replace(`${environment.ADMIN_PATH}/admin/logout`)
                            )
                        }

                        if (errorMessage) {
                            this.toastService.errorToast(errorMessage);
                        }
                    }
                )
            );
    }

    public delete(url: string, options?: RequestOptions, successMessage?: string, errorMessage?: string): Observable<any> {
        return this.httpClient
            .delete(url, options)
            .pipe(
                tap(
                    res => {
                        console.info(`${url} response received!`);
                        if (successMessage) {
                            this.toastService.successToast(successMessage);
                        }
                    },
                    err => {
                        console.info(`${url} response error!`);
                        console.error(err);
                        if (err.status == 418 || err.status == 403) {
                            this.authService.logout("email").subscribe(
                                res => window.location.replace(`${environment.ADMIN_PATH}/admin/logout`)
                            )
                        }

                        if (errorMessage) {
                            this.toastService.errorToast(errorMessage);
                        }
                    }
                )
            );
    }
}