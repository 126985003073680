import { Component, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { SportGroup } from '../../models/sport-group.model';
import { SportGroupService } from '../../services/sport-group.service';

@Component({
    templateUrl: 'sport-group-dialog.component.html',
})
export class SportGroupDialogComponent {
    @Input() group: SportGroup = {
        name: null,
        slug: null,
        icon: null
    };

    constructor(protected dialogRef: NbDialogRef<SportGroupDialogComponent>, private sportGroupService: SportGroupService) {

    }

    public updateSlug() {
        this.group.slug = this.group.name.toLowerCase().replace(' ', '-');
    }

    public cancel() {
        this.close(null);
    }

    public save() {
        this.sportGroupService.add(this.group).subscribe(
            (group: SportGroup) => this.close(group),
            (error) => console.log(error)
        )
    }

    private close(group: SportGroup) {
        this.dialogRef.close(group);
    }
}
