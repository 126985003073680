import { Component, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { UserService } from "../../pages/user/user.service";
import { User } from '../../models/user.model';

@Component({
    templateUrl: 'connect-athlete-dialog.component.html',
})
export class ConnectAthleteDialogComponent {
    @Input('connectModel') connectModel: any = {
        athleteId: null,
        userId: null,
    };

    public users: User[] = [];

    private offsetUsers: number = 0;
    private loading: boolean = false;

    private searchQuerry: string = "";

    constructor(
        protected dialogRef: NbDialogRef<ConnectAthleteDialogComponent>,
        private userService: UserService,
    ) {}

    ngOnInit() {
        this.users = [ this.connectModel.userId ];
        this.loadUsers(30, true)
    }

    public cancel() {
        this.close(false);
    }

    public save() {
        this.close();
    }

    private close(flag: boolean = true) {
        this.dialogRef.close(flag ? this.connectModel : null);
    }

    private loadUsers(howMany: number = 30, reset: boolean = false) {
        this.loading = true;
        if (reset && this.users) {
            this.offsetUsers = 0;
            if (this.connectModel.userId) {
                this.users = [ this.connectModel.userId ];
            } else {
                this.users = []
            }
        }
        this.userService.getUsers(howMany, this.offsetUsers).subscribe(users => {
                this.loading = false;
                users.forEach(user => {
                    if (this.users[0] && this.users[0].id != user.id) {
                        this.users.push(user);
                    } else if (!this.users[0]) {
                        this.users.push(user);
                    }
                });
            },
            error => {
                this.loading = false;
                this.offsetUsers -= howMany;
            }
            );
        this.offsetUsers += howMany;
    }

    onScrollUsers({ end }) {
        if (this.loading || this.users.length < this.offsetUsers) {
            return;
        }

        if (end + 10 >= this.offsetUsers) {
            if (this.searchQuerry != "") {
                if (this.searchQuerry.length >= 3) {
                    this.searchUsers();
                }
            } else {
                this.loadUsers();
            }
        }
    }

    searchOverride(term: string, item: any) {
        return true;
    }

    onSearchUsers({ term }) {
        if (this.searchQuerry != term) {
            this.offsetUsers = 0;
            this.searchQuerry = term ? term : "";
            if (this.searchQuerry == "") {
                this.loadUsers(30, true);
            }  else {
                if (this.loading || this.searchQuerry.length < 3) {
                    return;
                }
                this.users = [];
                this.searchUsers();
            }
        }
    }

    public searchUsers(howMany: number = 30) {
        this.loading = true;
        this.userService.searchUsers(this.searchQuerry.toLowerCase(), howMany, this.offsetUsers).subscribe(users => {
            this.loading = false;
            users.forEach(user => {
                this.users.push(user);
            });
        })

        this.offsetUsers += 30;
    }

    onClose(event: Event, dropdown: string) {
        this.loadUsers(30, true);
        this.searchQuerry = "";
    }
}
