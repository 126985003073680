import { Component, Input, ViewChild } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { CountryService } from '../../pages/country/country.service';
import { SportService } from '../../pages/sport/sport.service';
import { MediaService } from '../../pages/media/media.service';
import { User } from '../../models/user.model';

@Component({
    templateUrl: 'user-dialog.component.html',
})
export class UserDialogComponent {
    public countries: any[];

    public previewUrl: string;
    public fileForUpload: File;

    private offsetCountries: number = 0;
    private loading: boolean = false;

    private searchQuerry: string = "";

    @Input() user: User = {
        id: null,
        email: '',
        password: '',
        name: '',
        username: '',
        dob: '',
        avatar: '',
        description: '',
        country: null,
    };

    @ViewChild('fileInput', null) fileInput: any;

    constructor(
        protected dialogRef: NbDialogRef<UserDialogComponent>,
        private countryService: CountryService,
        private sportService: SportService,
        private mediaService: MediaService,
    ) {}

    ngOnInit() {
        console.log(this.user)
        this.countries = [ this.user.country ];
        this.initialize();
    }

    private initialize() {
        this.loadCountries(30, true);
    }

    pickFile() {
        if (this.fileInput) {
            this.fileInput.nativeElement.click();
        }
    }

    selectFile(file: File) {
        this.fileForUpload = file;

        var reader = new FileReader();
        reader.onload = (e: any) => this.previewUrl = e.target.result;

        reader.readAsDataURL(file);
    }

    public updateEmail() {
        this.user.email = this.user.email.trim();
    }

    public cancel() {
        this.close(false);
    }

    public save() {
        this.close();
    }

    public formValid() {
        if (
            this.user.email
            &&
            this.user.username
            &&
            this.user.name
        ) {
            return true;
        }

        return false;
    }

    private close(flag: boolean = true) {
        this.dialogRef.close(flag ? Object.assign({}, this.user, (this.fileForUpload ? { avatar: this.fileForUpload } : {})) : null);
    }

    searchOverride(term: string, item: any) {
        return true;
    }

    onScrollCountries({ end }) {
        if (this.loading || this.countries.length < this.offsetCountries) {
            return;
        }

        if (end + 10 >= this.offsetCountries) {
            if (this.searchQuerry != "") {
                if (this.searchQuerry.length >= 3) {
                    this.searchCountries();
                }
            } else {
                this.loadCountries();
            }
        }
    }

    private loadCountries(howMany: number = 30, reset: boolean = false) {
        this.loading = true;
        if (reset && this.countries) {
            this.offsetCountries = 0;
            if (this.user.country) {
                this.countries = [ this.user.country ];
            } else {
                this.countries = []
            }
        }
        this.countryService.getCountries(howMany, this.offsetCountries).subscribe(countries => {
                this.loading = false;
                countries.forEach(country => {
                    if (this.countries[0] && this.countries[0].id != country.id) {
                        this.countries.push(country);
                    } else if (!this.countries[0]) {
                        this.countries.push(country);
                    }
                });
            },
            error => {
                this.loading = false;
                this.offsetCountries -= howMany;
            }
            );
        this.offsetCountries += howMany;
    }

    onSearchCountries({ term }) {
        if (this.searchQuerry != term) {
            this.offsetCountries = 0;
            this.searchQuerry = term ? term : "";
            if (this.searchQuerry == "") {
                this.loadCountries(30, true);
            }  else {
                if (this.loading || this.searchQuerry.length < 3) {
                    return;
                }
                this.countries = [];
                this.searchCountries();
            }
        }
    }

    public searchCountries(howMany: number = 30) {
        this.loading = true;
        this.countryService.searchCountries(this.searchQuerry.toLowerCase(), howMany, this.offsetCountries).subscribe(countries => {
            this.loading = false;
            countries.forEach(country => {
                this.countries.push(country);
            });
        })

        this.offsetCountries += 30;
    }

    onClose(event: Event, dropdown: string) {
        this.loadCountries(30, true);
        this.searchQuerry = "";
    }
}
