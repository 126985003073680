import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NbAuthJWTToken, NbAuthService } from "@nebular/auth";
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class VideoService {
    private token: string;

    constructor(private httpClient: HttpClient, private authService: NbAuthService) {
        this.authService.onTokenChange()
            .subscribe((token: NbAuthJWTToken) => {
                this.token = token.toString();
            });
    }

    uploadVideo(video: File) {
        return new Promise((resolve, reject) => {
            this.getUploadUrl().subscribe(
                (response: any) => {
                    this.uploadVideoFile(response, video).subscribe(
                        (video: any) => {
                            this.createNewVideo(video).subscribe(
                                response => resolve(response),
                                error => reject(error),
                            );
                        },
                        (err: any) => reject(err),
                    );
                },
                (error: any) => reject(error),
            );
        });
    }

    private getUploadUrl() {
        return this.httpClient.get(`${environment.API_PATH}/video/upload?method=multipart`, {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${this.token}`,
            }),
        });
    }

    private createNewVideo(video: any) {
        return this.httpClient.post(`${environment.API_PATH}/video`, {
            key: video.media.key,
            size: video.file.size,
            status: 'PROCESSING',
        }, {
            headers: new HttpHeaders({
                'Authorization': `Bearer ${this.token}`,
            }),
        });
    }

    private uploadVideoFile(response: any, video: File) {
        const formData: FormData = new FormData();
        formData.append('uploadFile', video, video.name);

        const headers = new HttpHeaders({
            'X-Session-ID': response.session,
        });

        return this.httpClient.post(response.url, formData, {headers});
    }
}
