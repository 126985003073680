import { Component, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
    templateUrl: 'country-dialog.component.html',
})
export class CountryDialogComponent {
    @Input('dialogContext') dialogContext: string = 'add';
    @Input('country') country: any = {
        name: '',
        slug: '',
        icon: '',
        id: null,
    };

    constructor(protected dialogRef: NbDialogRef<CountryDialogComponent>) {

    }

    public updateSlug() {
        this.country.slug = this.country.name.toLowerCase().replace(' ', '-');
    }

    public cancel() {
        this.close(false);
    }

    public save() {
        this.close();
    }

    private close(flag: boolean = true) {
        this.dialogRef.close(flag ? this.country : null);
    }
}
