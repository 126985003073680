import { Component, Input, ViewChild } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { CountryService } from '../../pages/country/country.service';
import { Country } from '../../models/country.model';
import { Sport } from '../../models/sport.model';
import { SportService } from '../../pages/sport/sport.service';
import { MediaService } from '../../pages/media/media.service';
import { UserService } from "../../pages/user/user.service";
import { Athlete } from '../../models/athlete.model';
import { User } from '../../models/user.model';


@Component({
    templateUrl: 'athlete-dialog.component.html',
})
export class AthleteDialogComponent {
    @Input('dialogContext') dialogContext: string = 'add';
    @Input('athlete') athlete: Athlete = {
        name: null,
        dob: null,
        avatar: null,
        country: null,
        sport: null,
        isCelebrity: false,
        countryOfCompetition: null,
        gender: 0
    };

    public countries: any[] = [];
    public sports: any[] = [];
    public users: any[] = [];

    public genders: any[] = [
        {name: "Undefined", value: 0},
        {name: "Male", value: 1},
        {name: "Female", value: 2}
    ]

    public previewUrl: string;
    public fileForUpload: File;

    private offsetUsers: number = 0;
    private offsetSports: number = 0;
    private offsetCountries: number = 0;
    private loading: boolean = false;

    private searchQuerry: string = "";

    @ViewChild('fileInput', null) fileInput: any;

    constructor(
        protected dialogRef: NbDialogRef<AthleteDialogComponent>,
        private countryService: CountryService,
        private sportService: SportService,
        private mediaService: MediaService,
        private userService: UserService,
    ) {}

    ngOnInit() {
        this.users = [ this.athlete.authorId ];
        this.countries = [ this.athlete.country ];
        this.sports = [ this.athlete.sport ];
        this.initialize()
    }

    public cancel() {
        this.close(false);
    }

    pickFile() {
        if (this.fileInput) {
            this.fileInput.nativeElement.click();
        }
    }

    selectFile(file: File) {
        this.fileForUpload = file;

        var reader = new FileReader();
        reader.onload = (e: any) => this.previewUrl = e.target.result;

        reader.readAsDataURL(file);
    }

    public formIsValid() {
        return this.athlete && this.athlete.name && (this.athlete.avatar || this.fileForUpload) && this.athlete.sport && this.athlete.country;
    }

    public save() {
        this.close();
    }

    private close(flag: boolean = true) {
        if (flag && !this.athlete.club) {
            this.athlete.club = 'Proffesional';
        }

        if (flag && !this.athlete.authorId) {
            this.athlete.authorId = 1;
        }

        this.dialogRef.close(flag ? Object.assign(this.athlete, (this.fileForUpload ? { avatar: this.fileForUpload } : {})) : null);
    }

    private initialize() {
        this.loadUsers(30, true);
        this.loadCountries(30, true);
        this.loadSports(30, true);
    }

    onScrollUsers({ end }) {
        if (this.loading || this.users.length < this.offsetUsers) {
            return;
        }

        if (end + 10 >= this.offsetUsers) {
            if (this.searchQuerry != "") {
                if (this.searchQuerry.length >= 3) {
                    this.searchUsers();
                }
            } else {
                this.loadUsers();
            }
        }
    }

    onScrollCountries({ end }) {
        if (this.loading || this.countries.length < this.offsetCountries) {
            return;
        }

        if (end + 10 >= this.offsetCountries) {
            if (this.searchQuerry != "") {
                if (this.searchQuerry.length >= 3) {
                    this.searchCountries();
                }
            } else {
                this.loadCountries();
            }
        }
    }

    onScrollSports({ end }) {
        if (this.loading || this.sports.length < this.offsetSports) {
            return;
        }

        if (end + 10 >= this.offsetSports) {
            if (this.searchQuerry != "") {
                if (this.searchQuerry.length >= 3) {
                    this.searchSports();
                }
            } else {
                this.loadSports();
            }
        }
    }

    private loadUsers(howMany: number = 30, reset: boolean = false) {
        this.loading = true;
        if (reset && this.users) {
            this.offsetUsers = 0;
            if (this.athlete.authorId) {
                this.users = [ this.athlete.authorId ];
            } else {
                this.users = []
            }
        }
        this.userService.getUsers(howMany, this.offsetUsers).subscribe(users => {
                this.loading = false;
                users.forEach(user => {
                    if (this.users[0] && this.users[0].id != user.id) {
                        this.users.push(user);
                    } else if (!this.users[0]) {
                        this.users.push(user);
                    }
                });
            },
            error => {
                this.loading = false;
                this.offsetUsers -= howMany;
            }
            );
        this.offsetUsers += howMany;
    }

    private loadCountries(howMany: number = 30, reset: boolean = false) {
        this.loading = true;
        if (reset && this.countries) {
            this.offsetCountries = 0;
            if (this.athlete.country) {
                this.countries = [ this.athlete.country ];
            } else {
                this.countries = []
            }
        }
        this.countryService.getCountries(howMany, this.offsetCountries).subscribe(countries => {
                this.loading = false;
                countries.forEach(country => {
                    if (this.countries[0] && this.countries[0].id != country.id) {
                        this.countries.push(country);
                    } else if (!this.countries[0]) {
                        this.countries.push(country);
                    }
                });
            },
            error => {
                this.loading = false;
                this.offsetCountries -= howMany;
            }
            );
        this.offsetCountries += howMany;
    }

    private loadSports(howMany: number = 30, reset: boolean = false) {
        this.loading = true;
        if (reset && this.sports) {
            this.offsetSports = 0;
            if (this.athlete.sport) {
                this.sports = [ this.athlete.sport ];
            } else {
                this.sports = []
            }
        }
        this.sportService.getSports(howMany, this.offsetSports).subscribe(sports => {
                this.loading = false;
                sports.forEach(sport => {
                    if (this.sports[0] && this.sports[0].id != sport.id) {
                        this.sports.push(sport);
                    } else if (!this.sports[0]){
                        this.sports.push(sport);
                    }
                });
            },
            error => {
                this.loading = false;
                this.offsetSports -= howMany;
            }
            );
        this.offsetSports += howMany;
    }

    searchOverride(term: string, item: any) {
        return true;
    }

    onSearchUsers({ term }) {
        if (this.searchQuerry != term) {
            this.offsetUsers = 0;
            this.searchQuerry = term ? term : "";
            if (this.searchQuerry == "") {
                this.loadUsers(30, true);
            }  else {
                if (this.loading || this.searchQuerry.length < 3) {
                    return;
                }
                this.users = [];
                this.searchUsers();
            }
        }
    }

    onSearchCountries({ term }) {
        if (this.searchQuerry != term) {
            this.offsetCountries = 0;
            this.searchQuerry = term ? term : "";
            if (this.searchQuerry == "") {
                this.loadCountries(30, true);
            }  else {
                if (this.loading || this.searchQuerry.length < 3) {
                    return;
                }
                this.countries = [];
                this.searchCountries();
            }
        }
    }

    onSearchSports({ term }) {
        if (this.searchQuerry != term) {
            this.offsetSports = 0;
            this.searchQuerry = term ? term : "";
            if (this.searchQuerry == "") {
                this.loadSports(30, true);
            }  else {
                if (this.loading || this.searchQuerry.length < 3) {
                    return;
                }
                this.sports = [];
                this.searchSports();
            }
        }
    }

    public searchUsers(howMany: number = 30) {
        this.loading = true;
        this.userService.searchUsers(this.searchQuerry.toLowerCase(), howMany, this.offsetUsers).subscribe(users => {
            this.loading = false;
            users.forEach(user => {
                this.users.push(user);
            });
        })

        this.offsetUsers += 30;
    }

    public searchCountries(howMany: number = 30) {
        this.loading = true;
        this.countryService.searchCountries(this.searchQuerry.toLowerCase(), howMany, this.offsetCountries).subscribe(countries => {
            this.loading = false;
            countries.forEach(country => {
                this.countries.push(country);
            });
        })

        this.offsetCountries += 30;
    }

    public searchSports(howMany: number = 30) {
        this.loading = true;
        this.sportService.searchSports(this.searchQuerry.toLowerCase(), howMany, this.offsetSports).subscribe(sports => {
            this.loading = false;
            sports.forEach(sport => {
                this.sports.push(sport);
            });
        })

        this.offsetSports += 30;
    }

    onClose(event: Event, dropdown: string) {
        if (dropdown == "user") {
            this.loadUsers(30, true);
        } else if (dropdown == "country") {
            this.loadCountries(30, true);
        } else {
            this.loadSports(30, true)
        }
        this.searchQuerry = "";
    }
}
