import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NbAuthJWTToken, NbAuthService, NbTokenService } from "@nebular/auth";
import { environment } from '../../../environments/environment';
import { HttpService } from '../../services/http.service';

@Injectable({
    providedIn: 'root',
})
export class UserService {

    private token: string = '';

    constructor(private httpService: HttpService, private authService: NbAuthService, private tokenService: NbTokenService) {
        this.tokenService = tokenService;

        this.authService.onTokenChange()
            .subscribe((token: NbAuthJWTToken) => {
                this.token = token.toString();
            });
    }

    public refreshToken(token: string) {
        var jwtToken = new NbAuthJWTToken(token, "email")
        this.tokenService.set(jwtToken)
    }

    public getCurrentToken(): any {
        return new NbAuthJWTToken(this.token, "email").getPayload();
    }

    public searchUsers(search: string, limit: number = 10, offset: number = 0): Observable<any> {
        return this.httpService.get(
            `${environment.API_PATH}/user/search?q=${search}&limit=${limit}&offset=${offset}`,
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${this.token}`,
                }),
            },
            null,
            `Error searching users for term: ${search}`
        );
    }

    public getUsers(limit: number = 10, offset: number = 0): Observable<any> {
        return this.httpService.get(
            `${environment.API_PATH}/user?limit=${limit}&offset=${offset}`,
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${this.token}`,
                }),
            },
            null,
            `Error fetching users!`
        );
    }

    public addUser(user: any): Observable<any> {
        return this.httpService.post(
            `${environment.API_PATH}/user`,
            user,
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${this.token}`,
                }),
            },
            `User ${user.name} added.`,
            `Error adding user: ${user.name}`
        );
    }

    public updateUser(user: any): Observable<any> {
        return this.httpService.put(
            `${environment.API_PATH}/user/${user.id}`,
            user,
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${this.token}`,
                }),
            },
            `User ${user.name} updated.`,
            `Error updating user: ${user.name}`
        );
    }

    public deleteUser(user: any): Observable<any> {
        return this.httpService.delete(
            `${environment.API_PATH}/user/${user.id}`,
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${this.token}`,
                }),
            },
            `User ${user.name} deleted.`,
            `Error deleting user: ${user.name}`
        );
    }
}
