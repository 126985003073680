import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Post } from '../../models/post.model';
import { AthleteService } from '../../pages/athlete/athlete.service';
import { UserService } from '../../pages/user/user.service';
import { SportGroupService } from '../../services/sport-group.service';

@Component({
    selector: '',
    templateUrl: 'post-dialog.component.html',
})
export class PostDialogComponent {
    public sportGroups: any[];
    public athletes: any[];
    public users: any[];

    private offsetUsers: number = 0;
    private offsetSports: number = 0;
    private offsetAthletes: number = 0;
    private loading: boolean = false;

    private searchQuerry: string = "";

    @Input('post') post: Post = {
        athlete: null,
        sportGroup: null,
        user: null,
        video: null
    };

    private publishDate: Date = new Date()
    private date: Date = new Date()


    @Input('dialogContext') dialogContext: string = 'add';

    @Input('isEdit') isEdit: boolean = false;

    public error: string;

    @ViewChild('fileInput', null) fileInput: ElementRef;

    constructor(
        private sportGroupService: SportGroupService,
        private athleteService: AthleteService,
        private userService: UserService,
        protected dialogRef: NbDialogRef<PostDialogComponent>) {
    }

    ngOnInit() {
        this.date.setHours(this.date.getHours(), (Math.round(this.date.getMinutes()/10) + 1) * 10, 0, 0);
        this.publishDate.setTime(this.date.getTime())

        this.users = [ this.post.user ];
        this.athletes = [ this.post.athlete ];
        this.sportGroups = [ this.post.sportGroup ];
        this.initialize();
    }

    create() {
        this.dialogRef.close({post: this.post, publishTime: this.publishDate.getTime()});
    }

    cancel() {
        this.dialogRef.close({post: false});
    }

    pickFile() {
        if (this.fileInput) {
            this.fileInput.nativeElement.click();
        }
    }

    selectFile(file: File) {
        this.post.video = file;
    }

    postIsValid() {
        if (
            this.dialogContext === 'edit'
            ||
            this.post.video && this.post.sportGroup && this.post.user
        ) {
            return true;
        }

        return false;
    }

    private initialize() {
        this.loadUsers(30, true);
        this.loadAthletes(30, true);
        this.loadSports(30, true);
    }


    onScrollUsers({ end }) {
        if (this.loading || this.users.length < this.offsetUsers) {
            return;
        }

        if (end + 10 >= this.offsetUsers) {
            if (this.searchQuerry != "") {
                if (this.searchQuerry.length >= 3) {
                    this.searchUsers();
                }
            } else {
                this.loadUsers();
            }
        }
    }

    onScrollAthletes({ end }) {
        if (this.loading || this.athletes.length < this.offsetAthletes) {
            return;
        }

        if (end + 10 >= this.offsetAthletes) {
            if (this.searchQuerry != "") {
                if (this.searchQuerry.length >= 3) {
                    this.searchAthletes();
                }
            } else {
                this.loadAthletes();
            }
        }
    }

    onScrollSports({ end }) {
        if (this.loading || this.sportGroups.length < this.offsetSports) {
            return;
        }

        if (end + 10 >= this.offsetSports) {
            if (this.searchQuerry != "") {
                if (this.searchQuerry.length >= 3) {
                    this.searchSports();
                }
            } else {
                this.loadSports();
            }
        }
    }

    private loadUsers(howMany: number = 30, reset: boolean = false) {
        this.loading = true;
        if (reset && this.users) {
            this.offsetUsers = 0;
            if (this.post.user) {
                this.users = [ this.post.user ];
            } else {
                this.users = []
            }
        }
        this.userService.getUsers(howMany, this.offsetUsers).subscribe(users => {
                this.loading = false;
                users.forEach(user => {
                    if (this.users[0] && this.users[0].id != user.id) {
                        this.users.push(user);
                    } else if (!this.users[0]) {
                        this.users.push(user);
                    }
                });
            },
            error => {
                this.loading = false;
                this.offsetUsers -= howMany;
            }
            );
        this.offsetUsers += howMany;
    }

    private loadAthletes(howMany: number = 30, reset: boolean = false) {
        this.loading = true;
        if (reset && this.athletes) {
            this.offsetAthletes = 0;
            if (this.post.athlete) {
                this.athletes = [ this.post.athlete ];
            } else {
                this.athletes = []
            }
        }
        this.athleteService.getAthletes(howMany, this.offsetAthletes).subscribe(athletes => {
                this.loading = false;
                athletes.forEach(athlete => {
                    if (this.athletes[0] && this.athletes[0].id != athlete.id) {
                        this.athletes.push(athlete);
                    } else if (!this.athletes[0]) {
                        this.athletes.push(athlete);
                    }
                });
            },
            error => {
                this.loading = false;
                this.offsetAthletes -= howMany;
            }
            );
        this.offsetAthletes += howMany;
    }

    private loadSports(howMany: number = 30, reset: boolean = false) {
        this.loading = true;
        if (reset && this.sportGroups) {
            this.offsetSports = 0;
            if (this.post.sportGroup) {
                this.sportGroups = [ this.post.sportGroup ];
            } else {
                this.sportGroups = [];
            }
        }
        this.sportGroupService.fetchSportsGroups(howMany, this.offsetSports).subscribe(sportGroups => {
                this.loading = false;
                sportGroups.forEach(sportGroup => {
                    if (this.sportGroups[0] && this.sportGroups[0].id != sportGroup.id) {
                        this.sportGroups.push(sportGroup);
                    } else if (!this.sportGroups[0]) {
                        this.sportGroups.push(sportGroup);
                    }
                });
            },
            error => {
                this.loading = false;
                this.offsetSports -= howMany;
            }
            );
        this.offsetSports += howMany;
    }

    searchOverride(term: string, item: any) {
        return true;
    }

    onSearchUsers({ term }) {
        if (this.searchQuerry != term) {
            this.offsetUsers = 0;
            this.searchQuerry = term ? term : "";
            if (this.searchQuerry == "") {
                this.loadUsers(30, true);
            }  else {
                if (this.loading || this.searchQuerry.length < 3) {
                    return;
                }
                this.users = [];
                this.searchUsers();
            }
        }
    }

    onSearchAthletes({ term }) {
        if (this.searchQuerry != term) {
            this.offsetAthletes = 0;
            this.searchQuerry = term ? term : "";
            if (this.searchQuerry == "") {
                this.loadAthletes(30, true);
            }  else {
                if (this.loading || this.searchQuerry.length < 3) {
                    return;
                }
                this.athletes = [];
                this.searchAthletes();
            }
        }
    }

    onSearchSports({ term }) {
        if (this.searchQuerry != term) {
            this.offsetSports = 0;
            this.searchQuerry = term ? term : "";
            if (this.searchQuerry == "") {
                this.loadSports(30, true);
            }  else {
                if (this.loading || this.searchQuerry.length < 3) {
                    return;
                }
                this.sportGroups = [];
                this.searchSports();
            }
        }
    }

    public searchUsers(howMany: number = 30) {
        this.loading = true;
        this.userService.searchUsers(this.searchQuerry.toLowerCase(), howMany, this.offsetUsers).subscribe(users => {
            this.loading = false;
            users.forEach(user => {
                this.users.push(user);
            });
        })

        this.offsetUsers += 30;
    }

    public searchAthletes(howMany: number = 30) {
        this.loading = true;
        this.athleteService.searchAthletes(this.searchQuerry.toLowerCase(), howMany, this.offsetAthletes).subscribe(athletes => {
            this.loading = false;
            athletes.forEach(athlete => {
                this.athletes.push(athlete);
            });
        })

        this.offsetAthletes += 30;
    }

    public searchSports(howMany: number = 30) {
        this.loading = true;
        this.sportGroupService.searchSportsGroups(this.searchQuerry.toLowerCase(), howMany, this.offsetSports).subscribe(sportGroups => {
            this.loading = false;
            sportGroups.forEach(sportGroup => {
                this.sportGroups.push(sportGroup);
            });
        })

        this.offsetSports += 30;
    }

    onClose(event: Event, dropdown: string) {
        if (dropdown == "user") {
            this.loadUsers(30, true);
        } else if (dropdown == "athlete") {
            this.loadAthletes(30, true);
        } else {
            this.loadSports(30, true)
        }
        this.searchQuerry = "";
    }
}
