import { Injectable } from '@angular/core';
import { NbToastrService, NbToastrConfig } from '@nebular/theme';

@Injectable({
    providedIn: 'root'
})
export class ToastService {
    constructor(private toastrService: NbToastrService) { }

    public successToast(message: string, title: string = 'Success', config?: Partial<NbToastrConfig>) {
        this.toastrService.success(message, title, config);
    }

    public errorToast(message: string, title: string = 'Warning', config?: Partial<NbToastrConfig>) {
        this.toastrService.danger(message, title, config);
    }

    public infoToast(message: string, title: string = 'Info', config?: Partial<NbToastrConfig>) {
        this.toastrService.info(message, title, config);
    }
}