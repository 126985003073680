import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { environment } from '../../../environments/environment';
import { HttpService } from '../../services/http.service';

@Injectable({
    providedIn: 'root',
})
export class CountryService {

    private token: string;

    constructor(private httpService: HttpService, private authService: NbAuthService) {
        this.authService.onTokenChange()
            .subscribe((token: NbAuthJWTToken) => {
                this.token = token.toString();
            });
    }

    public searchCountries(search: string, limit: number = 10, offset: number = 0): Observable<any> {
        return this.httpService.get(
            `${environment.API_PATH}/country/search?q=${search}&limit=${limit}&offset=${offset}`,
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${this.token}`,
                }),
            },
            null,
            `Error searching countries for term: ${search}`
        );
    }

    public getCountries(limit: number = 10, offset: number = 0): Observable<any> {
        return this.httpService.get(
            `${environment.API_PATH}/country?limit=${limit}&offset=${offset}`,
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${this.token}`,
                }),
            },
            null,
            `Error fetching countries.`
        );
    }

    public addCountry(country: any): Observable<any> {
        return this.httpService.post(
            `${environment.API_PATH}/country`,
            country,
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${this.token}`,
                }),
            },
            `Country ${country.name} added.`,
            `Error adding country: ${country.name}`
        );
    }

    public updateCountry(country: any): Observable<any> {
        return this.httpService.put(
            `${environment.API_PATH}/country`,
            country,
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${this.token}`,
                }),
            },
            `Country ${country.name} updated.`,
            `Error updating country: ${country.name}`
        );
    }

    public deleteCountry(country: any): Observable<any> {
        return this.httpService.delete(
            `${environment.API_PATH}/country/${country.id}`,
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${this.token}`,
                }),
            },
            `Country ${country.name} deleted.`,
            `Error deleting country: ${country.name}`
        );
    }
}
