import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { SportGroupService } from '../../services/sport-group.service';
import { SportGroup } from '../../models/sport-group.model';

@Component({
    templateUrl: 'sport-group-picker-dialog.component.html',
})
export class SportGroupPickerDialogComponent {
    public selectedGroup: SportGroup = null;
    public sportGroups: SportGroup[] = [];

    constructor(
        protected dialogRef: NbDialogRef<SportGroupPickerDialogComponent>,
        private sportGroupService: SportGroupService
    ) {
        this.fetchGroups();
    }

    private fetchGroups() {
        return this.sportGroupService.fetch().subscribe((groups: SportGroup[]) => {
            this.sportGroups.unshift({ id: null, name: 'Add New', slug: null, icon: null });
            this.sportGroups = groups;
        });
    }

    public shouldDisable() {
        return this.selectedGroup === null;
    }

    public cancel() {
        this.dialogRef.close(false);
    }

    public select(flag: boolean = true) {
        this.dialogRef.close(flag ? this.selectedGroup : null);
    }
}
