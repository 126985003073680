import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NbAuthJWTToken, NbAuthService } from "@nebular/auth";
import { HttpService } from '../../../services/http.service';
import { environment } from '../../../../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class TransactionService {

    private token: string;

    constructor(private httpService: HttpService, private authServce: NbAuthService) {
        this.authServce.onTokenChange()
            .subscribe((token: NbAuthJWTToken) => {
                this.token = token.toString();
            });
    }

    public getTotalSTYN(): Observable<any> {
        return this.httpService.get(
            `${environment.API_PATH}/wallet/transaction/total`,
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${this.token}`,
                }),
            },
            null,
            `Error fetching total!!!`
        );
    }
}
