import { Component, Input } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NbDialogRef } from '@nebular/theme';
import { Post } from '../../models/post.model';
import { Video } from '../../models/video.model';
import Hls from 'hls.js'

@Component({
    templateUrl: 'video-dialog.component.html',
})
export class VideoDialogComponent {
    @Input() video: Video;
    @Input() post: Post;

    constructor(protected dialogRef: NbDialogRef<VideoDialogComponent>) {

    }

    ngAfterViewInit() {
        this.pokreniSource();
    }

    public close() {
        this.dialogRef.close();
    }

    public pokreniSource() {
        var videoPlayer = <HTMLVideoElement>document.getElementById('video_' + this.video.id);
        var videoSrc = this.video.link;
        if (videoPlayer !== null) {
            if (videoPlayer.canPlayType('application/vnd.apple.mpegurl')) {
                videoPlayer.src = videoSrc;
                videoPlayer.addEventListener('loadedmetadata', function() {
                    videoPlayer.play();
                });
            } else if (Hls.isSupported()) {
                var hls = new Hls();
                hls.loadSource(videoSrc);
                hls.attachMedia(videoPlayer);
                hls.on(Hls.Events.MANIFEST_PARSED, function() {
                    videoPlayer.play();
                });
            }
        }
    }
}
