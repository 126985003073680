import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SportGroup } from '../../models/sport-group.model';
import { Sport } from '../../models/sport.model';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { environment } from '../../../environments/environment';
import { HttpService } from '../../services/http.service';

@Injectable({
    providedIn: 'root',
})
export class SportService {

    private token: string;

    constructor(
        private httpService: HttpService,
        private authService: NbAuthService,
    ) {
        this.authService.onTokenChange()
            .subscribe((token: NbAuthJWTToken) => {
                this.token = token.toString();
            });
    }

    public searchSports(search: string, limit: number = 10, offset: number = 0): Observable<any> {
        return this.httpService.get(
            `${environment.API_PATH}/sport/search?q=${search}&limit=${limit}&offset=${offset}`,
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${this.token}`,
                }),
            },
            null,
            `Error searching sports for term: ${search}`
        );
    }

    public getSports(limit: number = 10, offset: number = 0): Observable<any> {
        return this.httpService.get(
            `${environment.API_PATH}/sport?limit=${limit}&offset=${offset}`,
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${this.token}`,
                }),
            },
            null,
            `Error fetching sports!`
        );
    }

    public getSport(id: number): Observable<any> {
        return this.httpService.get(
            `${environment.API_PATH}/sport/${id}?withGroups=true`,
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${this.token}`,
                }),
            },
            null,
            `Error fetching sport: ${id}`
        );
    }

    public addSport(sport: any): Observable<any> {
        return this.httpService.post(
            `${environment.API_PATH}/sport`,
            sport,
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${this.token}`,
                }),
            },
            `Sport ${sport.name} added.`,
            `Error adding sport: ${sport.name}`
        );
    }

    public updateSport(sport: any): Observable<any> {
        return this.httpService.put(
            `${environment.API_PATH}/sport`,
            sport,
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${this.token}`,
                }),
            },
            `Sport ${sport.name} updated.`,
            `Error updating sport: ${sport.name}`
        );
    }

    public deleteSport(sport: any): Observable<any> {
        return this.httpService.delete(
            `${environment.API_PATH}/sport/${sport.id}`,
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${this.token}`,
                }),
            },
            `Sport ${sport.name} removed.`,
            `Error removing sport: ${sport.name}`
        );
    }

    public addSportGroup(sport: Sport, group: SportGroup): Observable<any> {
        return this.httpService.put(
            `${environment.API_PATH}/sport/${sport.id}/${group.id}`,
            null,
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${this.token}`,
                }),
            },
            `Sport group ${group.name} added.`,
            `Error adding sport group: ${group.name}`
        );
    }

    public removeSportGroup(sport: Sport, group: SportGroup): Observable<any> {
        return this.httpService.delete(
            `${environment.API_PATH}/sport/${sport.id}/${group.id}`,
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${this.token}`,
                }),
            },
            `Sport group ${group.name} removed.`,
            `Error removing sport group: ${group.name}`
        );
    }
}
