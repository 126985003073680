import { Component, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
    templateUrl: 'amount-dialog.component.html',
})
export class AmountDialogComponent {

    public amount: string = ""

    constructor(protected dialogRef: NbDialogRef<AmountDialogComponent>) {

    }

    public cancel() {
        this.close(false);
    }

    public confirm() {
        this.close();
    }

    private close(isHeSure: boolean = true) {
        this.dialogRef.close({"confirm": isHeSure, "amount": this.amount});
    }
}
