import { Component, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { CountryService } from '../../pages/country/country.service';
import { Country } from '../../models/country.model';
import { MediaService } from '../../pages/media/media.service';
import { UserService } from "../../pages/user/user.service";
import { User } from '../../models/user.model';

@Component({
    templateUrl: 'scout-dialog.component.html',
})
export class ScoutDialogComponent {
    @Input('scout') scout: any = {
        name: '',
        dob: '',
        avatar: '',
        avatarUrl: '',
        country: '',
        user: '',
        id: null,
    };

    public countries: Country[] = [];
    public users: User[] = [];

    constructor(
        protected dialogRef: NbDialogRef<ScoutDialogComponent>,
        private countryService: CountryService,
        private mediaService: MediaService,
        private userService: UserService,
    ) {
        this.fetchCountries();
        this.fetchUsers();
    }

    public cancel() {
        this.close(false);
    }

    public async uploadFiles(files: File[]) {

        // The given files collection is actually a "live collection", which means that
        // it will be cleared once the Input is cleared. As such, we need to create a
        // local copy of it so that it doesn't get cleared during the asynchronous file
        // processing within the for-of loop.
        for (const file of Array.from(files)) {

            try {
                this.mediaService.uploadMedia(file)
                    .subscribe((media: any) => {
                        this.scout.avatar = media.id;
                        this.scout.avatarUrl = media.url;
                    });
            } catch (error) {
                console.warn('File upload failed.');
                console.error(error);
            }

            return;
        }

    }

    public save() {
        this.close();
    }

    private close(flag: boolean = true) {
        this.dialogRef.close(flag ? this.scout : null);
    }

    private fetchCountries() {
        return this.countryService.getCountries(0).subscribe((countries: Country[]) => {
            this.countries = countries;
        });
    }

    private fetchUsers() {
        return this.userService.getUsers(10000).subscribe((users: User[]) => {
            this.users = users;
        });
    }
}
