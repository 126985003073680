import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { environment } from '../../../environments/environment';

@Injectable()
export class MediaService {

    private token: string = '';

    constructor(private httpClient: HttpClient, private authServce: NbAuthService) {
        this.authServce.onTokenChange()
            .subscribe((token: NbAuthJWTToken) => {
                this.token = token.toString();
            });
    }

    public uploadMedia(file: File): Observable<any> {
        const formData = new FormData();
        formData.append(`file`, file, file.name);

        return this.httpClient.post(`${environment.API_PATH}/media`, formData,
            {
                // NOTE: Because we are posting a Blob (File is a specialized Blob
                // object) as the POST body, we have to include the Content-Type
                // header. If we don't, the server will try to parse the body as
                // plain text.
                headers: new HttpHeaders({
                    // 'Content-Type': file.type,
                    'Authorization': `Bearer ${this.token}`,
                }),
                params: {
                    clientFilename: file.name,
                    mimeType: file.type,
                },
            });
    }
}
