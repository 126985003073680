import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
    templateUrl: 'sport-dialog.component.html',
})
export class SportDialogComponent {
    @Input('dialogContext') dialogContext: string = 'add';
    @Input() sport: any = {
        name: '',
        slug: '',
        icon: '',
        id: null,
    };

    @ViewChild('fileInput', null) fileInput: ElementRef;

    private fileForUpload: File;
    public filePreview: string;

    constructor(protected dialogRef: NbDialogRef<SportDialogComponent>) {
        
    }

    pickFile() {
        if (this.fileInput) {
            this.fileInput.nativeElement.click();
        }
    }

    selectFile(file: File) {
        this.fileForUpload = file;

        var reader = new FileReader();
        reader.onload = (e: any) => this.sport.icon = e.target.result;

        reader.readAsDataURL(file);
    }

    public updateSlug() {
        this.sport.slug = 
            this.sport.name
                .toLowerCase()
                .replace(' ', '-');
    }

    public cancel() {
        this.close(false);
    }

    public save() {
        this.close();
    }

    private close(flag: boolean = true) {
        this.dialogRef.close(flag ? Object.assign({}, this.sport, this.fileForUpload ? { icon: this.fileForUpload } : {}) : null);
    }
}
