import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NbAuthJWTToken, NbAuthService } from "@nebular/auth";
import { environment } from '../../../environments/environment';
import { HttpService } from '../../services/http.service';

@Injectable({
    providedIn: 'root'
})
export class AthleteService {

    private token: string;

    constructor(private httpService: HttpService, private authServce: NbAuthService) {
        this.authServce.onTokenChange()
            .subscribe((token: NbAuthJWTToken) => {
                this.token = token.toString();
            });
    }

    public searchAthletes(search: string, limit: number = 10, offset: number = 0): Observable<any> {
        return this.httpService.get(
            `${environment.API_PATH}/athlete/search?q=${search}&limit=${limit}&offset=${offset}`,
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${this.token}`,
                }),
            },
            null,
            `Error searching athletes for term: ${search}`
        );
    }

    public getAthletes(limit: number = 10, offset: number = 0): Observable<any> {
        return this.httpService.get(
            `${environment.API_PATH}/athlete?limit=${limit}&offset=${offset}`,
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${this.token}`,
                }),
            },
            null,
            `Error fetching athletes.`
        );
    }

    public addAthlete(athlete: any): Observable<any> {
        return this.httpService.post(
            `${environment.API_PATH}/athlete`,
            athlete,
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${this.token}`,
                }),
            },
            `Athlete ${athlete.name} added.`,
            `Error adding athlete: ${athlete.name}`
        );
    }

    public connectWithUser(connectModel: any): Observable<any> {
        return this.httpService.put(
            `${environment.API_PATH}/athlete/connect`,
            connectModel,
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${this.token}`,
                }),
            },
            connectModel.userId ? 
                `Connected user ${connectModel.userId} with athlete ${connectModel.athleteId}` :
                `Disconnected user from athlete ${connectModel.athleteId}`,
            connectModel.userId ? 
                `Error connecting user ${connectModel.userId} with athlete ${connectModel.athleteId}` :
                `Error disconnecting user from athlete ${connectModel.athleteId}`
        );
    }

    public updateAthlete(athlete: any): Observable<any> {
        return this.httpService.put(
            `${environment.API_PATH}/athlete/${athlete.id}`,
            athlete,
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${this.token}`,
                }),
            },
            `Athlete ${athlete.name} updated.`,
            `Error updating athlete: ${athlete.name}`
        );
    }

    public deleteAthlete(athlete: any): Observable<any> {
        return this.httpService.delete(
            `${environment.API_PATH}/athlete/${athlete.id}`,
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${this.token}`,
                }),
            },
            `Athlete ${athlete.name} deleted.`,
            `Error deleting athlete: ${athlete.name}`
        );
    }

    public distributionReward(athlete: any, amount: number): Observable<any> {
        return this.httpService.put(
            `${environment.API_PATH}/wallet/transaction/distributionreward/${athlete.id}`,
            {"amount": amount},
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${this.token}`,
                }),
            },
            `Athlete ${athlete.name} rewarded.`,
            `Error rewarding athlete: ${athlete.name}`
        );
    }
}
