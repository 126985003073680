import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SportGroup } from '../models/sport-group.model';
import { NbAuthJWTToken, NbAuthService } from '@nebular/auth';
import { environment } from '../../environments/environment';
import { HttpService } from './http.service';

@Injectable({
    providedIn: 'root',
})
export class SportGroupService {

    private token: string;

    constructor(private httpService: HttpService, private authService: NbAuthService) {
        this.authService.onTokenChange()
            .subscribe((token: NbAuthJWTToken) => {
                this.token = token.toString();
            });
    }

    public fetch(): Observable<any> {
        return this.httpService.get(
            `${environment.API_PATH}/sport-group?limit=1000`,
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${this.token}`,
                }),
            },
            null,
            `Error fetching sport groups!`
        );

    }

    public searchSportsGroups(querry: string, limit: number = 10000, offset: number = 0): Observable<any> {
        return this.httpService.get(
            `${environment.API_PATH}/sport-group/search?q=${querry}&limit=${limit}&offset=${offset}`,
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${this.token}`,
                }),
            },
            null,
            `Error searching sport groups!`
        );

    }

    public fetchSportsGroups(limit: number = 10000, offset: number = 0): Observable<any> {
        return this.httpService.get(
            `${environment.API_PATH}/sport-group?limit=${limit}&offset=${offset}`,
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${this.token}`,
                }),
            },
            null,
            `Error fetching sport groups!`
        );

    }

    public add(group: SportGroup): Observable<any> {
        return this.httpService.post(
            `${environment.API_PATH}/sport-group`, group,
            {
                headers: new HttpHeaders({
                    'Authorization': `Bearer ${this.token}`,
                }),
            },
            `Sport group ${group.name} added.`,
            `Error adding sport group: ${group.name}`
        );
    }
}
