import { Component, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
    templateUrl: 'reason-dialog.component.html',
})
export class ReasonDialogComponent {

    public reason: string = ""

    constructor(protected dialogRef: NbDialogRef<ReasonDialogComponent>) {

    }

    public no() {
        this.close(false);
    }

    public yes() {
        this.close();
    }

    private close(isHeSure: boolean = true) {
        this.dialogRef.close({"confirm": isHeSure, "reason": this.reason});
    }
}
