import { Component, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
    templateUrl: 'payment-plan-dialog.component.html',
})
export class PaymentPlanDialogComponent {
    @Input('dialogContext') dialogContext: string = 'add';
    @Input('plan') plan: any = {
        name: null,
        displayName: null,
        duration: null,
        price: null,
        currency: null,
        type: null,
        validUntil: null,
        maxStorage: null,
        maxAthletes: null,
        monthlyPromotions: null
    };

    constructor(
        protected dialogRef: NbDialogRef<PaymentPlanDialogComponent>
    ) {
    }

    public cancel() {
        this.close(false);
    }

    public formIsValid() {
        return this.plan.name && this.plan.displayName;
    }

    public save() {
        this.close();
    }

    private close(flag: boolean = true) {
        this.dialogRef.close(flag ? this.plan : null);
    }
}
