import { Component, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
    templateUrl: 'delete-dialog.component.html',
})
export class DeleteDialogComponent {
    @Input() message: string;

    constructor(protected dialogRef: NbDialogRef<DeleteDialogComponent>) {

    }

    public no() {
        this.close(false);
    }

    public yes() {
        this.close();
    }

    private close(flag: boolean = true) {
        this.dialogRef.close(flag);
    }
}
